import * as React from "react";
import { Layout } from "../../../components/layout";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Pedigree } from "../../../components/dogs/pedigree";

const WurfPage = (props) => {
  const data = props.data.wuerfeYaml;
  return (
    <>
      <Layout
        title={data.fullName}
        image={
          data.teaser
            ? data.teaser.childImageSharp.gatsbyImageData
            : data.mainImage.childImageSharp.gatsbyImageData
        }
      >
        <main className="container-lg">
          <h1 className="text-center mb-5">{data.fullName}</h1>
          <section className="row">
            <GatsbyImage
              imgClassName="shadow"
              quality={80}
              className="col-12 col-md-6"
              image={
                data.littercard
                  ? data.littercard.childImageSharp.gatsbyImageData
                  : data.mainImage.childImageSharp.gatsbyImageData
              }
              alt={data.name}
              objectFit="contain"
              loading="lazy"
            />
            <div className="col-md-6">
              <h2 className="my-5 text-center">
                {data.father.name} & {data.mother.name}
              </h2>
              <h5 className="">Geboren am: {data.date}</h5>
              <p>{data.description}</p>
            </div>
          </section>
          {data.father.father && (
            <section className="my-3">
              <h2 className="text-center">Ahnentafel</h2>
              <Pedigree father={data.father} mother={data.mother} />
            </section>
          )}
          <section className="container-fluid p-0">
            {data.images.map((week) => (
              <>
                <h2 className="text-center mt-5 mb-2">{week.name}</h2>
                <div className="row g-2 justify-content-evenly">
                  {week.img.map((img) => (
                    <GatsbyImage
                      imgClassName="shadow"
                      quality={80}
                      className={
                        img.childImageSharp.fixed.aspectRatio < 1
                          ? "col-md-3"
                          : "col-md-4"
                      }
                      image={img.childImageSharp.gatsbyImageData}
                      alt={data.name}
                      objectFit="contain"
                      loading="lazy"
                    />
                  ))}
                </div>
              </>
            ))}
          </section>
        </main>
      </Layout>
    </>
  );
};

export const query = graphql`
  query ($id: String) {
    wuerfeYaml(id: { eq: $id }) {
      name
      fullName
      date
      description
      mainImage {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      teaser {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      littercard {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      images {
        name
        img {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
            fixed {
              aspectRatio
            }
          }
        }
      }
      father {
        normalName
        img {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        father {
          father {
            name
          }
          mother {
            name
          }
          name
        }
        mother {
          father {
            name
          }
          mother {
            name
          }
          name
        }
        name
      }
      mother {
        normalName
        img {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        father {
          father {
            name
          }
          mother {
            name
          }
          name
        }
        name
        mother {
          father {
            name
          }
          mother {
            name
          }
          name
        }
      }
    }
  }
`;

export default WurfPage;
