import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

export const Pedigree = ({ mother, father, useImage = true }) => (
  <>
    {/*<h3>Ahnentafel</h3>*/}
    <div className="row align-items-center">
      {mother.img && father.img && useImage ? (
        <div className="row col-md-3 text-center align-items-center">
          <div className="p-2">
            <GatsbyImage
              className="shadow"
              image={father.img.childImageSharp.gatsbyImageData}
              alt={father.name}
              loading="lazy"
            />
            <h3 className="fs-4">{father?.name}</h3>
          </div>
          <div className="p-2">
            <GatsbyImage
              className="shadow"
              image={mother.img.childImageSharp.gatsbyImageData}
              alt={mother.name}
              loading="lazy"
            />
            <h3 className="fs-4">{mother?.name}</h3>
          </div>
        </div>
      ) : null}
      <div
        className={
          "table-responsive " + (mother.img && father.img && useImage ? "col-md-9" : "")
        }
      >
        <table className="table table-striped table-bordered text-center">
          <thead>
            <tr>
              <th>Vater</th>
              <th>Großeltern</th>
              <th>Urgroßeltern</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="align-middle" rowspan="4">
                {father?.name}
              </td>
              <td className="align-middle" rowspan="2">
                {father?.father?.name}
              </td>
              <td>{father?.father?.father?.name}</td>
            </tr>
            <tr>
              <td>{father?.father?.mother?.name}</td>
            </tr>
            <tr>
              <td className="align-middle" rowspan="2">
                {father?.mother?.name}
              </td>
              <td>{father?.mother?.father?.name}</td>
            </tr>
            <tr>
              <td>{father?.mother?.mother?.name}</td>
            </tr>
          </tbody>
          <thead>
            <tr>
              <th>Mutter</th>
              <th>Großeltern</th>
              <th>Urgroßeltern</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="align-middle" rowspan="4">
                {mother?.name}
              </td>
              <td className="align-middle" rowspan="2">
                {mother?.father?.name}
              </td>
              <td>{mother?.father?.father?.name}</td>
            </tr>
            <tr>
              <td>{mother?.father?.mother?.name}</td>
            </tr>
            <tr>
              <td className="align-middle" rowspan="2">
                {mother?.mother?.name}
              </td>
              <td>{mother?.mother?.father?.name}</td>
            </tr>
            <tr>
              <td>{mother?.mother?.mother?.name}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </>
);
